<template>
  <div>
    <headers />
    <div style="overflow-x: hidden">
      <div class="main-grid mb-30">
        <a
          draggable="false"
          target="_self"
          class="main-grid--in-grid transition-opacity duration-500 hover:opacity-80 focus:opacity-80"
          href="/impact"
          ><div
            class="relative transition-opacity duration-300 overflow-hidden"
            data-projection-id="278"
            style="opacity: 1; transform: none"
          >
            <div
              data-testid="tout-image-bg"
              class="col-start-2 col-span-23 rounded md:h-full w-full sm:relative md:absolute top-0 left-0 z-[-1] overflow-hidden"
            >
              <img style="width: 100%; height: 100%;" :src="require('@/assets/images/news/b1.png')" alt="" />
            </div>
            <div
              class="md:h-[550px] grid grid-cols-[35px_repeat(3,_1fr_20px)_1fr_35px] md:h-[550px] md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr] col-start-2 col-span-full relative"
            >
              <div
                class="col-end-flex flex flex-col z-1 col-start-1 col-span-15 md:col-start-3 lg:col-start-3 justify-center"
              >
                <div class="mt-[30px] md:my-0" data-projection-id="279" style="opacity: 1; transform: none">
                  <h1 class="text-2xl text-iblack-700 dark:text-iblack-300 md:dark:text-iblack-700">
                    New $10 Million Award Launched<br class="hidden md:inline" />
                    to Improve Maternal and Infant Health<br class="hidden md:inline" />
                    Outcomes Around the World
                  </h1>
                </div>
              </div>
            </div>
          </div></a
        >
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-11 col-start-2 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/impact"
                    ><section class="" data-projection-id="219">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        data-projection-id="220"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/news/b2.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-5">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            data-projection-id="221"
                            style="opacity: 1; transform: none"
                          >
                            Five Outstanding Organizations Working To Save Democracy in the United States
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="222"
                            style="opacity: 1; transform: none"
                          >
                            The Stronger Democracy Award is a $22M grant competition to help improve political
                            representation and increase participation in the United States’ democratic process. We’re
                            thrilled to introduce the five finalists.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="col-span-11 col-start-14 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/impact"
                    ><section class="" data-projection-id="223">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        data-projection-id="224"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/news/b3.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-5">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            data-projection-id="225"
                            style="opacity: 1; transform: none"
                          >
                            Harnessing the Untapped Power of Female Workers to Enhance Climate Resiliency
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="226"
                            style="opacity: 1; transform: none"
                          >
                            To celebrate the launch of the ZEN CAPITAL Impact Climate Equity Co-Lab, we're highlighting the
                            three portfolio organizations helping women protect their lives and our planet through
                            climate-related employment.&nbsp;
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block">
          <div
            role="separator"
            aria-orientation="horizontal"
            class="w-full h-full block"
            data-projection-id="227"
            style="background-color: rgb(155, 155, 155); opacity: 1; transform: none"
          ></div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-2 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/impact"
                    ><section class="" data-projection-id="228">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        data-projection-id="229"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/news/b4.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            data-projection-id="230"
                            style="opacity: 1; transform: none"
                          >
                            Empowering Women and Indigenous Leaders to Reclaim and Protect Their Land
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="231"
                            style="opacity: 1; transform: none"
                          >
                            To celebrate the launch of the ZEN CAPITAL Impact Climate Equity Co-Lab, we're highlighting the
                            three portfolio organizations supporting hyperlocal environmental leaders.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-10 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/impact"
                    ><section class="" data-projection-id="232">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        data-projection-id="233"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/news/b5.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            data-projection-id="234"
                            style="opacity: 1; transform: none"
                          >
                            How Sustainable Agriculture Can Lift Women and Their Families Out of Poverty
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="235"
                            style="opacity: 1; transform: none"
                          >
                            To celebrate the launch of the ZEN CAPITAL Impact Climate Equity Co-Lab, we're highlighting the
                            three portfolio organizations lifting women and their families out of poverty by improving
                            sustainable farming practices.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="col-span-7 col-start-18 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/impact"
                    ><section class="" data-projection-id="236">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        data-projection-id="237"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/news/b6.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            data-projection-id="238"
                            style="opacity: 1; transform: none"
                          >
                            Strengthening Female-led Conservation to Combat Climate Change
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="239"
                            style="opacity: 1; transform: none"
                          >
                            To celebrate the launch of the ZEN CAPITAL Impact Climate Equity Co-Lab, we're highlighting the
                            four portfolio organizations fighting climate change by strengthening female-led
                            conservation and land stewardship.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block">
          <div
            role="separator"
            aria-orientation="horizontal"
            class="w-full h-full block"
            data-projection-id="240"
            style="background-color: rgb(155, 155, 155); opacity: 1; transform: none"
          ></div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-2 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/impact"
                    ><section class="" data-projection-id="241">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        data-projection-id="242"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/news/b7.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            data-projection-id="243"
                            style="opacity: 1; transform: none"
                          >
                            ZEN CAPITAL Impact Launches the $50M Climate Equity Co-Lab
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="244"
                            style="opacity: 1; transform: none"
                          >
                            We're thrilled to announce the launch of the ZEN CAPITAL Impact Climate Equity Co-Lab—a $50
                            million philanthropic fund supporting organizations at the intersection of climate change,
                            gender equity, and poverty.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-10 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/impact"
                    ><section class="" data-projection-id="245">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        data-projection-id="246"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/news/b8.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            data-projection-id="247"
                            style="opacity: 1; transform: none"
                          >
                            Celebrating World Refugee Day by Placing Power and Resources in the Hands of Refugees
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="248"
                            style="opacity: 1; transform: none"
                          >
                            To celebrate World Refugee Day, ZEN CAPITAL Impact shares the story behind the Larsen Lam ZEN CAPITAL
                            Impact Award and how it's helping shift power and resources back into the hands of refugees.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="col-span-7 col-start-18 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/impact"
                    ><section class="" data-projection-id="249">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        data-projection-id="250"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/news/b9.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            data-projection-id="251"
                            style="opacity: 1; transform: none"
                          >
                            Building a Durable Future for Refugees with the Larsen Lam ZEN CAPITAL Impact Award
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="252"
                            style="opacity: 1; transform: none"
                          >
                            Meet the five finalists of the first ZEN CAPITAL Impact Award: a $12 million grant sponsored by
                            Chris Larsen and Lyna Lam, with support from the Sea Grape Foundation, to provide impactful
                            programs for refugees.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block">
          <div
            role="separator"
            aria-orientation="horizontal"
            class="w-full h-full block"
            data-projection-id="253"
            style="background-color: rgb(155, 155, 155); transform: scale(0.25) translateZ(0px)"
          ></div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-2 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/impact"
                    ><section class="" data-projection-id="254">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        data-projection-id="255"
                        style="transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/news/b10.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            data-projection-id="256"
                            style="transform: translateY(20px) translateZ(0px)"
                          >
                            New $12 Million Grant Competition Seeks to Create a Durable Future for Refugees
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="257"
                            style="transform: translateY(20px) translateZ(0px)"
                          >
                            Larsen Lam ZEN CAPITAL Impact Award is first in a series of ZEN CAPITAL Impact Competitions managed by
                            Lever for Change.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-10 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/impact"
                    ><section class="" data-projection-id="258">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        data-projection-id="259"
                        style="transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/news/b11.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            data-projection-id="260"
                            style="transform: translateY(20px) translateZ(0px)"
                          >
                            Larsen Lam ZEN CAPITAL Impact Award Grants Over $24 Million to Create Long-Term Transformational
                            Change for Refugees
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="261"
                            style="transform: translateY(20px) translateZ(0px)"
                          >
                            The Resourcing Refugee Leadership Initiative is selected as the $10M award recipient by
                            sponsors Chris Larsen and Lyna Lam, while DREAMS for Refugees is selected as the $2M award
                            recipient by the Sea Grape Foundation.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="col-span-7 col-start-18 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/impact"
                    ><section class="" data-projection-id="262">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        data-projection-id="263"
                        style="transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/news/b12.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            data-projection-id="264"
                            style="transform: translateY(20px) translateZ(0px)"
                          >
                            Resourcing Refugee Leadership Initiative selected by sponsors Chris Larsen and Lyna Lam as
                            the $10 million recipient of the Larsen Lam ZEN CAPITAL Impact Award
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="265"
                            style="transform: translateY(20px) translateZ(0px)"
                          >
                            Meet the leaders of the Resourcing Refugee Leadership Initiative and discover how they will
                            use the award to provide impactful programs for refugees.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block">
          <div
            role="separator"
            aria-orientation="horizontal"
            class="w-full h-full block"
            data-projection-id="266"
            style="background-color: rgb(155, 155, 155); transform: scale(0.25) translateZ(0px)"
          ></div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-2 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/impact"
                    ><section class="" data-projection-id="267">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        data-projection-id="268"
                        style="transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/news/b13.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            data-projection-id="269"
                            style="transform: translateY(20px) translateZ(0px)"
                          >
                            Larsen Lam ZEN CAPITAL Impact Award Funds Four Finalist Organizations Creating a Better Future
                            for Refugees
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="270"
                            style="transform: translateY(20px) translateZ(0px)"
                          >
                            Meet the inspiring individuals leading these four impactful organizations.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-10 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/impact"
                    ><section class="" data-projection-id="271">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        data-projection-id="272"
                        style="transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/news/b14.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            data-projection-id="273"
                            style="transform: translateY(20px) translateZ(0px)"
                          >
                            ZEN CAPITAL Impact and Lever for Change Announce a $10M Increase to the Stronger Democracy Award
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="274"
                            style="transform: translateY(20px) translateZ(0px)"
                          >
                            An anonymous donor in the ZEN CAPITAL Impact community contributes $10M to the Stronger Democracy
                            Award, bringing the total funding to $22M.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="col-span-7 col-start-18 relative">
                <div>
                  <section class="" data-projection-id="275">
                    <div class="block md:grid md:grid-cols-6 md:gap-4"><div class="col-span-6"></div></div>
                  </section>
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footers />
  </div>
</template>
<script>
import headers from '@/components/head'
import footers from '@/components/foot'
export default {
  components: {
    headers,
    footers,
  },
}
</script>
